import { ACTIVATION_STEPS_KEY } from 'constants/signup';
import {
  addCountryCodeNumber,
  isRegisteredViaBetaFunnel,
  isSllrUser
} from './helpers';

export const getSignUpPath = (isFromSllr = false) =>
  `/${isFromSllr || isSllrUser() ? 'sllr-' : ''}signup`;

export const getActivationStepIndex = (stepKey) => {
  const {
    VERIFY_EMAIL,
    SHIPPING_PRICES,
    BUSINESS_PROFILE,
    PICKUP_LOCATION,
    BANK_ACCOUNT,
    ACCOUNT_TYPE,
    SUMMARY
  } = ACTIVATION_STEPS_KEY;

  const orderedSteps = [
    VERIFY_EMAIL,
    ...(isSllrUser() ? [SHIPPING_PRICES] : []),
    BUSINESS_PROFILE,
    PICKUP_LOCATION,
    ...(isRegisteredViaBetaFunnel() ? [SHIPPING_PRICES] : []),
    BANK_ACCOUNT,
    ACCOUNT_TYPE,
    SUMMARY
  ];

  return orderedSteps.findIndex((value) => value === stepKey);
};

export const getUserProfileData = ({ fullName, phone, country }) => {
  const [firstName, ...rest] = fullName.trim().split(' ');
  const lastName = rest.join(' ');

  return {
    firstName,
    lastName,
    phone: addCountryCodeNumber({ phoneNumber: phone, country })
  };
};
