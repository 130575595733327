import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';

import { getLocaleFromLocalStorage } from 'utils/intl-wrapper';
import { COUNTRY_CURRENCY } from './helpers';
import { formatNumbersBasedOnLanguage } from 'utils/intl-wrapper';
import { LOCALE } from './intl-wrapper';
import {
  TODAY,
  YESTERDAY,
  LAST_WEEK,
  LAST_MONTH,
  LAST_YEAR,
  THIS_WEEK_TO_DATE,
  THIS_MONTH_TO_DATE,
  THIS_YEAR_TO_DATE
} from './date-picker';
import { getLocalizedValue } from 'utils/bilingual-values';

import { fmt } from 'components/IntlWrapper/IntlWrapper';

import { ReactComponent as FileUploadIcon } from 'assets/bosta-icons/File-upload.svg';
import { ReactComponent as SaveForLaterIcon } from 'assets/bosta-icons/save-for-later.svg';
import { ReactComponent as Facebook } from 'assets/bosta-icons/facebook-shop.svg';
import { ReactComponent as FacebookMessenger } from 'assets/bosta-icons/facebook-messenger.svg';
import { ReactComponent as Instagram } from 'assets/bosta-icons/instagram.svg';
import { ReactComponent as FacebookGray } from 'assets/bosta-icons/facebook-gray.svg';
import { ReactComponent as FacebookMessengerGray } from 'assets/bosta-icons/facebook-messenger-gray.svg';
import { ReactComponent as InstagramGray } from 'assets/bosta-icons/instagram-gray.svg';
import { ReactComponent as CursorClickIcon } from 'assets/bosta-icons/cursor-click.svg';
import { ReactComponent as ClockIcon } from 'assets/bosta-icons/Clock.svg';
import { ReactComponent as OrdersIcon } from 'assets/bosta-icons/Orders.svg';
import { ReactComponent as PickupIcon } from 'assets/bosta-icons/Pickups.svg';
import { ReactComponent as BankNoteIcon } from 'assets/bosta-icons/bank-note.svg';
import { ReactComponent as ShieldTickTealIcon } from 'assets/bosta-icons/shield-tick-teal.svg';
import { ReactComponent as ImageTealIcon } from 'assets/bosta-icons/image-teal.svg';
import { ReactComponent as KeyTealIcon } from 'assets/bosta-icons/key-teal.svg';

const locale = getLocaleFromLocalStorage();

export const ADD_PRODUCTS_MODAL_CONTENT = [
  {
    icon: <FileUploadIcon />,
    title: fmt({ id: 'products.add_products_modal.single_title' }),
    subtitle: fmt({ id: 'products.add_products_modal.single_subtitle' })
  },
  {
    icon: <SaveForLaterIcon />,
    title: fmt({ id: 'products.add_products_modal.multiple_title' }),
    subtitle: fmt({ id: 'products.add_products_modal.multiple_subtitle' })
  }
];

export const PRODUCTS_QUICK_FILTERS = [
  {
    label: fmt({
      id: 'products.products_table.quick_filters.all'
    }),
    value: undefined
  },
  {
    label: fmt({
      id: 'products.products_table.quick_filters.active'
    }),
    value: 'activate'
  },
  {
    label: fmt({
      id: 'products.products_table.quick_filters.inactive'
    }),
    value: 'deactivate'
  },
  {
    label: fmt({
      id: 'products.products_table.quick_filters.low_stock'
    }),
    value: 'lowStock'
  }
];

export const ACTIVE_OPTIONS = {
  ACTIVATE: 'activate',
  DEACTIVATE: 'deactivate',
  LOW_STOCK: 'lowStock'
};

export const SLIDE_OVER = {
  MAIN_IMAGE_INDEX: 0,
  MAX_IMAGES_NUMBER_INDEX: 4,
  MAX_IMAGES_NUMBER: 5
};

export const SLIDE_OVER_ACTIONS = {
  EDIT: 'edit',
  DUPLICATE: 'duplicate',
  SELECT: 'select'
};

export const getSllrUrl = (id, domain) => {
  return `https://${domain || SLLRCO}/p/${id}`;
};

export const getSllrShortenedUrl = (id, domain) => {
  return `${domain || SLLRCO}/p/${id}`;
};

export const MAGIC_LINK_LEARN_MORE_URL = `https://bosta.freshdesk.com/${locale}/support/solutions/articles/36000385778-order-magic-link`;

export const CATEGORIES_ERROR_MSG_CODES = {
  12011: fmt({
    id: 'products.add_products.price.category_field.error_msgs.category_exists'
  })
};

export const CATEGORIES_TYPES = {
  DEFAULT: 'default',
  CUSTOM: 'custom'
};

export const IMAGE_MAX_SIZE_BY_MB = 10; // MB => Megabyte

export const SLLR = 'sllr.co/';

export const SLLRCO = 'sllr.co';

export const SLLR_URL = 'https://sllr.co/b/';

export const ACCEPTED_IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

export const SHEET_MAX_NUM_OF_PRODUCTS = 500;

export const IMPORT_MULTIPLE_PRODUCTS_HELP_LINK = `https://bosta.freshdesk.com/${locale}/support/solutions/articles/36000385776-importing-multiple-products`;

export const MULTIPLE_IMPORT_STEPS_KEYS = {
  UPLOAD_FILE: 'UPLOAD_FILE',
  PREVIEW_FILE: 'PREVIEW_FILE',
  VALIDATING_AND_UPLOADING: 'VALIDATING_AND_UPLOADING',
  PREVIEW_ERRORS: 'PREVIEW_ERRORS'
};

export const UNCREATED_SLLR_MODAL_DATA = {
  title: fmt({
    id: 'products.uncreated_sllr.modal.title'
  }),
  subtitle: fmt({
    id: 'products.uncreated_sllr.modal.subtitle'
  }),
  buttonText: fmt({
    id: 'products.uncreated_sllr.modal.confirm'
  }),
  cancelText: fmt({
    id: 'products.uncreated_sllr.modal.cancel'
  })
};

export const FILE_SHEET_UPLOAD_ERROR_TYPES = {
  INVALID_FILE: 'INVALID_FILE',
  EMPTY_FILE: 'EMPTY_FILE',
  EXCEEDED_MAX_ROWS: 'EXCEEDED_MAX_ROWS'
};

export const EMPTY_STATE = {
  sllr_store_table: 'sllr_store_table',
  sllr_store_analytics: 'sllr_store_analytics',
  catalog_table_mobile: 'catalog_table_mobile',
  catalog_tableAR_mobile: 'catalog_tableAR_mobile',
  catalog_table: 'catalog_table',
  catalog_tableAR: 'catalog_tableAR',
  empty_card1: 'empty_card1'
};

export const MAX_PRODUCT_NAME_COUNT = 256;

export const PRODUCT_RETURN_TYPE = 'return';

export const OPTION_LIST_TYPE = 'DragableOptionList';

export const DONE = 'done';
export const VARIANT_PRICE = 'variantPrice';
export const VARIANT_ORIGINAL_PRICE = 'variantOriginalPrice';

export const PRODUCT_PAGE_TYPE = {
  CREATE: 'create',
  DUPLICATE: 'duplicate',
  EDIT: 'edit'
};

export const PRODUCT_VARIANTS_KEY = {
  PRODUCT_VARIANCES: 'productVariances',
  EDITED_VARIANCES: 'editedVariances'
};

export const PRODUCT_OPTIONS_LABEL = {
  SIZE: fmt({
    id: 'products.add_products.product_options.options.size'
  }),
  COLOR: fmt({
    id: 'products.add_products.product_options.options.color'
  }),
  BRAND: fmt({
    id: 'products.add_products.product_options.options.brand'
  }),
  CONDITION: fmt({
    id: 'products.add_products.product_options.options.condition'
  }),
  MATERIAL: fmt({
    id: 'products.add_products.product_options.options.material'
  }),
  STORAGE: fmt({
    id: 'products.add_products.product_options.options.storage'
  })
};

export const PRODUCT_OPTIONS = [
  {
    label: PRODUCT_OPTIONS_LABEL.SIZE,
    value: 'Size'
  },
  {
    label: PRODUCT_OPTIONS_LABEL.COLOR,
    value: 'Color'
  },
  {
    label: PRODUCT_OPTIONS_LABEL.BRAND,
    value: 'Brand'
  },
  {
    label: PRODUCT_OPTIONS_LABEL.CONDITION,
    value: 'Condition'
  },
  {
    label: PRODUCT_OPTIONS_LABEL.MATERIAL,
    value: 'Material'
  },
  {
    label: PRODUCT_OPTIONS_LABEL.STORAGE,
    value: 'Storage'
  }
];

export const PRODUCT_OPTIONS_PLACEHOLDER = {
  Size: fmt({
    id: 'products.add_products.product_options.options.size_placeholder'
  }),
  Color: fmt({
    id: 'products.add_products.product_options.options.color_placeholder'
  }),
  Brand: fmt({
    id: 'products.add_products.product_options.options.brand_placeholder'
  }),
  Condition: fmt({
    id: 'products.add_products.product_options.options.condition_placeholder'
  }),
  Material: fmt({
    id: 'products.add_products.product_options.options.material_placeholder'
  }),
  Storage: fmt({
    id: 'products.add_products.product_options.options.storage_placeholder'
  })
};

export const PRICE_TYPES = {
  DEFAULT: 'default',
  ORIGINAL: 'original'
};

export const DEFAULT_MINIMUM = 250;
export const DEFAULT_SUBTOTAL = 240;
export const DEFAULT_FEES = 30;
export const MIN_AMOUNT = 1;
export const MAX_AMOUNT = 1000;
export const CUSTOM_CONDITION_DEFAULT = 30;
export const CUSTOM_CONDITION_ELSE = 50;
export const STORE_INFO = 'StoreInfo';
export const FREE_PLAN = 'SLLR_FREE_SHIPPING_FEES';
export const CONDITIONAL_PLAN = 'SLLR_CONDITIONAL_SHIPPING_FEES';
export const FLAT_RATE_PLAN = 'SLLR_FLAT_RATE_SHIPPING_FEES';
export const CUSTOM_RATE_PLAN = 'SLLR_CUSTOM_RATE_SHIPPING_FEES';
export const MIN_DATE = 1;
export const INITIAL_MAX_DATE = 4;

export const FILE_LIST_TYPE = 'DragableUploadList';

export const INCREASE_SLLR_SALES_TABS_VALUE = {
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  MESSENGER: 'MESSENGER'
};

export const INCREASE_SLLR_SLAES_TABS_CONTENT = {
  FACEBOOK: [
    {
      value: 'isFacebookPageDone',
      title: fmt({
        id: 'products.sllr_sale_increase.modal.facebook_page.title'
      }),
      description: fmt({
        id: 'products.sllr_sale_increase.modal.facebook_page.description'
      })
    },
    {
      value: 'isFacebookProductsDone',
      title: fmt({
        id: 'products.sllr_sale_increase.modal.facebook_products.title'
      }),
      description: fmt({
        id: 'products.sllr_sale_increase.modal.facebook_products.description'
      })
    },
    {
      value: 'isFacebookPostsDone',
      title: fmt({
        id: 'products.sllr_sale_increase.modal.facebook_posts.title'
      }),
      description: fmt({
        id: 'products.sllr_sale_increase.modal.facebook_posts.description'
      })
    },
    {
      value: 'isFacebookStoriesDone',
      title: fmt({
        id: 'products.sllr_sale_increase.modal.facebook_stories.title'
      }),
      description: fmt({
        id: 'products.sllr_sale_increase.modal.facebook_stories.description'
      })
    },
    {
      value: 'isFacebookAdsDone',
      title: fmt({
        id: 'products.sllr_sale_increase.modal.facebook_ads.title'
      }),
      description: fmt({
        id: 'products.sllr_sale_increase.modal.facebook_ads.description'
      })
    }
  ],
  INSTAGRAM: [
    {
      value: 'isInstagramBioDone',
      title: fmt({
        id: 'products.sllr_sale_increase.modal.instagram_bio.title'
      }),
      description: fmt({
        id: 'products.sllr_sale_increase.modal.instagram_bio.description'
      })
    },
    {
      value: 'isInstagramProductsDone',
      title: fmt({
        id: 'products.sllr_sale_increase.modal.instagram_products.title'
      }),
      description: fmt({
        id: 'products.sllr_sale_increase.modal.instagram_products.description'
      })
    },
    {
      value: 'isInstagramPostsDone',
      title: fmt({
        id: 'products.sllr_sale_increase.modal.instagram_posts.title'
      }),
      description: fmt({
        id: 'products.sllr_sale_increase.modal.instagram_posts.description'
      })
    },
    {
      value: 'isInstagramStoriesDone',
      title: fmt({
        id: 'products.sllr_sale_increase.modal.instagram_stories.title'
      }),
      description: fmt({
        id: 'products.sllr_sale_increase.modal.instagram_stories.description'
      })
    },
    {
      value: 'isInstagramAdsDone',
      title: fmt({
        id: 'products.sllr_sale_increase.modal.instagram_ads.title'
      }),
      description: fmt({
        id: 'products.sllr_sale_increase.modal.instagram_ads.description'
      })
    }
  ],
  MESSENGER: [
    {
      value: 'isMessengerChatDone',
      title: fmt({
        id: 'products.sllr_sale_increase.modal.messenger_chat.title'
      }),
      description: fmt({
        id: 'products.sllr_sale_increase.modal.messenger_chat.description'
      })
    },
    {
      value: 'isMessengerAutoreplyDone',
      title: fmt({
        id: 'products.sllr_sale_increase.modal.messenger_autoreply.title'
      }),
      description: fmt({
        id: 'products.sllr_sale_increase.modal.messenger_autoreply.description'
      })
    }
  ]
};

export const INCREASE_SLLR_SALES_TABS_GIFS_URLS = {
  FACEBOOK: {
    isFacebookPageDone: 'https://storage.googleapis.com/bosta-fe/FB_1.gif',
    isFacebookProductsDone:
      'https://storage.googleapis.com/bosta-fe/FB_IG_2.gif',
    isFacebookPostsDone: 'https://storage.googleapis.com/bosta-fe/FB_3.gif',
    isFacebookStoriesDone: 'https://storage.googleapis.com/bosta-fe/FB_4.gif',
    isFacebookAdsDone: 'https://storage.googleapis.com/bosta-fe/FB_5.gif'
  },
  INSTAGRAM: {
    isInstagramBioDone: 'https://storage.googleapis.com/bosta-fe/IG_1.gif',
    isInstagramProductsDone:
      'https://storage.googleapis.com/bosta-fe/FB_IG_2.gif',
    isInstagramPostsDone: 'https://storage.googleapis.com/bosta-fe/IG_3.gif',
    isInstagramStoriesDone: 'https://storage.googleapis.com/bosta-fe/IG_4.gif',
    isInstagramAdsDone: 'https://storage.googleapis.com/bosta-fe/IG_5.gif'
  },
  MESSENGER: {
    isMessengerChatDone: 'https://storage.googleapis.com/bosta-fe/MSG_1.gif',
    isMessengerAutoreplyDone:
      'https://storage.googleapis.com/bosta-fe/MSG_2.gif'
  }
};

export const INCREASE_SLLR_SALES_MODAL_TABS = [
  {
    value: 'FACEBOOK',
    name: fmt({
      id: 'products.sllr_sale_increase.modal.facebook'
    }),
    icon: <Icon component={FacebookGray} className="ant-icon-sm" />,
    activeIcon: <Icon component={Facebook} className="ant-icon-sm" />,
    content: INCREASE_SLLR_SLAES_TABS_CONTENT.FACEBOOK
  },
  {
    value: 'INSTAGRAM',
    name: fmt({
      id: 'products.sllr_sale_increase.modal.instagram'
    }),
    icon: <Icon component={InstagramGray} className="ant-icon-sm" />,
    activeIcon: <Icon component={Instagram} className="ant-icon-sm" />,
    content: INCREASE_SLLR_SLAES_TABS_CONTENT.INSTAGRAM
  },
  {
    value: 'MESSENGER',
    name: fmt({
      id: 'products.sllr_sale_increase.modal.messenger'
    }),
    icon: <Icon component={FacebookMessengerGray} className="ant-icon-sm" />,
    activeIcon: <Icon component={FacebookMessenger} className="ant-icon-sm" />,
    content: INCREASE_SLLR_SLAES_TABS_CONTENT.MESSENGER
  }
];

export const SLLR_LOGO = 'SllrLogo';
export const BOSTA_SLLR_LOGO = 'BostaSllrLogo';
export const PRODUCTS_URL = '/products';
export const ORDERS_URL = '/orders';
export const FLYERS_DASHBOARD = 'FLYERS_DASHBOARD';

export const ACTIVATION_BANNER_IMG_URL =
  'https://storage.googleapis.com/bosta-fe/SllrActivationBannerImg.svg';

export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const CHANGE = 'CHANGE';

export const FEATURES_TOUR_KEYS = {
  SLLR_SETTINGS_TOUR: 'isSllrSettingsTourViewed',
  SHIPPING_ORDERS_TOUR: 'isShippingOrdersTourViewed',
  START_SELLING_TOUR: 'isStartSellingTourViewed',
  ORDERS_PAGE_TOUR: 'isOrdersPageTourViewed'
};

export const SLLR_ANALYTICS_DEFAULT_DATE_FILTER_INDEX = 5;
export const SLLR_ANALYTICS_DISABLED_DATE = '09-04-2022';

export const SLLR_ANALYTICS_CARDS = [
  {
    title: fmt({ id: 'products.sllr_store.analytics.cards.store_visits' }),
    tooltipText: fmt({
      id: 'products.sllr_store.analytics.cards.sotre_visits_tooltip'
    }),
    icon: <Icon component={CursorClickIcon} className="ant-icon-lg" />,
    color: 'blue',
    key: 'storeViews'
  },
  {
    title: fmt({ id: 'products.sllr_store.analytics.cards.req_orders' }),
    tooltipText: fmt({
      id: 'products.sllr_store.analytics.cards.req_orders_tooltip'
    }),
    icon: <Icon component={ClockIcon} className="ant-icon-lg" />,
    color: 'orange',
    key: 'requestedOrdersCount'
  },
  {
    title: fmt({ id: 'products.sllr_store.analytics.cards.confirmed_orders' }),
    tooltipText: fmt({
      id: 'products.sllr_store.analytics.cards.confirmed_orders_tooltip'
    }),
    icon: <Icon component={OrdersIcon} className="ant-icon-lg" />,
    color: 'gray',
    key: 'confirmedOrdersCount'
  },
  {
    title: fmt({ id: 'products.sllr_store.analytics.cards.picked_up_orders' }),
    tooltipText: fmt({
      id: 'products.sllr_store.analytics.cards.picked_up_orders_tooltip'
    }),
    icon: <Icon component={PickupIcon} className="ant-icon-lg" />,
    color: 'gray',
    key: 'pickedUpOrReceivedOrdersCount'
  },
  {
    title: fmt({ id: 'products.sllr_store.analytics.cards.revenue' }),
    tooltipText: fmt({
      id: 'products.sllr_store.analytics.cards.revenue_tooltip'
    }),
    icon: <Icon component={BankNoteIcon} className="ant-icon-lg" />,
    color: 'green',
    isMonetaryValue: true,
    key: 'confirmedOrdersRevenue'
  }
];

export const SLLR_ANALYTICS_COLUMNS = ({
  isSmallMobileScreen,
  currentOnboardingStep
}) => [
  {
    title: fmt({
      id: 'products.sllr_store.analytics.table.columns.products'
    }),
    dataIndex: 'name',
    render: (name, record) => getProductName({ ...record }),
    ...(isSmallMobileScreen && {
      width: 40
    }),
    ...(currentOnboardingStep && {
      width: 100
    }),
    ...((isSmallMobileScreen || currentOnboardingStep) && {
      render: (name) => (
        <Tooltip
          title={name}
          overlayClassName="br-sllr-analytics__table__product-name__tooltip"
          getPopupContainer={() => document.body}
        >
          <span className="br-sllr-analytics__table__product-name">{name}</span>
        </Tooltip>
      )
    })
  },
  {
    title: fmt({
      id: 'products.sllr_store.analytics.table.columns.previews'
    }),
    dataIndex: 'overviewCount',
    width: currentOnboardingStep ? 64 : isSmallMobileScreen ? 25 : 160
  },
  {
    title: fmt({
      id: 'products.sllr_store.analytics.table.columns.sold'
    }),
    dataIndex: 'orderedCount',
    width: currentOnboardingStep ? 64 : isSmallMobileScreen ? 25 : 160
  },
  {
    title: fmt({
      id: 'products.sllr_store.analytics.table.columns.revenue'
    }),
    dataIndex: 'revenue',
    width: currentOnboardingStep ? 64 : isSmallMobileScreen ? 25 : 120,
    render: (value) =>
      fmt(
        {
          id: COUNTRY_CURRENCY
        },
        {
          cod: formatNumbersBasedOnLanguage(value)
        }
      )
  }
];

export const SLLR_ANALYTICS_CUSTOM_RANGES = (intl) =>
  intl.locale === LOCALE.AR
    ? {
        اليوم: TODAY,
        أمس: YESTERDAY,
        'هذا الاسبوع': THIS_WEEK_TO_DATE,
        'الاسبوع الماضي': LAST_WEEK,
        'هذا الشهر': THIS_MONTH_TO_DATE,
        'الشهر الماضي': LAST_MONTH,
        'هذا العام': THIS_YEAR_TO_DATE,
        'العام الماضي': LAST_YEAR
      }
    : {
        Today: TODAY,
        Yesterday: YESTERDAY,
        'This week': THIS_WEEK_TO_DATE,
        'Last week': LAST_WEEK,
        'This month': THIS_MONTH_TO_DATE,
        'Last month': LAST_MONTH,
        'This year': THIS_YEAR_TO_DATE,
        'Last year': LAST_YEAR
      };

export const SOCIAL_LINKS_REGEX = {
  INSTAGRAM: new RegExp(
    '(?:(?:http|https)://)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)/'
  ),
  FACEBOOK: new RegExp(
    '(?:https?://)?(?:www.)?(mbasic.facebook|m.facebook|facebook|fb).(com|me)/'
  )
};

export const SOCIAL_LINKS_PLACEHOLDERS = {
  INSTAGRAM: 'Instagram.com/',
  FACEBOOK: 'Facebook.com/'
};

export const SOCIAL_LINKS_NAMES = {
  WHATSAPP: 'whatsApp',
  INSTAGRAM: 'instagram',
  FACEBOOK: 'facebook',
  PHONE: 'phone'
};

export const WHATSAPP_ID = 'storeInfo_socialLinks_whatsApp';

export const ASCENDING = 'asc';
export const DESCENDING = 'desc';

export const DIRECTION = {
  UP: 'up',
  DOWN: 'down'
};

export const SHIPPING = 'shipping';
export const POLICIES = 'policies';
export const GENERAL = 'general';
export const PAYMENTS = 'payments';
export const DOMAINS = 'domains';
export const PUDO = 'pudo';
export const DIFF_DAYS_TO_SHOW_INCREASE_MODAL = 2;

export const UPLOAD_IMAGE_MODAL_POINTS = [
  {
    title: fmt({ id: 'products.upload_image_modal.points.point_one.title' }),
    subtitle: fmt({
      id: 'products.upload_image_modal.points.point_one.subtitle'
    }),
    icon: <ShieldTickTealIcon className="ant-icon-lg" />
  },
  {
    title: fmt({ id: 'products.upload_image_modal.points.point_two.title' }),
    subtitle: fmt({
      id: 'products.upload_image_modal.points.point_two.subtitle'
    }),
    icon: <ImageTealIcon className="ant-icon-lg" />
  },
  {
    title: fmt({ id: 'products.upload_image_modal.points.point_three.title' }),
    subtitle: fmt({
      id: 'products.upload_image_modal.points.point_three.subtitle'
    }),
    icon: <KeyTealIcon className="ant-icon-lg" />
  }
];

export const EMPTY_RICH_FORMATTER_TEXT = '<p><br></p>';
export const LESS_THAN = 'lessThan';
export const ALWAYS = 'always';
export const LOW_STOCK_DEFAULT = 5;

export const FILE_TYPE_MAPPER = {
  '.png': 'image/png',
  '.jpeg': 'image/jpeg',
  '.jpg': 'image/jpg',
  '.pdf': 'application/pdf'
};

export const getProductName = ({ name, nameAr }) =>
  getLocalizedValue({ value: name, valueAr: nameAr });
