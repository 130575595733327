import React, { useState, useEffect, useRef, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { Table, Tooltip, Select, Switch, Spin, Radio } from 'antd';
import classnames from 'classnames';
import Icon, { LoadingOutlined } from '@ant-design/icons';
import { mediaHook } from 'customHooks';

import {
  STEPPER,
  PAYMENT_FREQUENCY,
  PICKUP,
  SEND,
  NORMAL
} from 'constants/signup';
import { formatPricingTableData } from 'utils/pricing';
import { COUNTRY_CURRENCY, isSaudi } from 'constants/helpers';
import { getSignupPricingInfo } from 'services/signup';
import { PICKUP_LOCATION_OPTIONS } from 'constants/pricing';
import { isInstantCashoutPlanAvailable } from 'constants/countries/countries-mapping';
import { sendSegment } from 'utils/segment';
import { isSllrUser } from 'utils/helpers';
import { useModal } from 'contexts/modalProvider.context';
import { ContextWrapper } from 'contexts/wrapper.context';

import { notify } from 'components/Notify/Notify';
import PricingPlanCard from './components/PricingPlanCard';
import BRButton from 'components/BRButton/BRButton';
import CashoutGuideModal from 'components/CashoutGuideModal/CashoutGuideModal';
import PricingCalculatorBanner from 'components/PricingCalculatorBanner/PricingCalculatorBanner';

import { ReactComponent as ArrowRight } from 'assets/bosta-icons/Chevron-right.svg';
import { ReactComponent as ArrowLeft } from 'assets/bosta-icons/Chevron-left.svg';
import { ReactComponent as Check } from 'assets/bosta-icons/Check.svg';
import { ReactComponent as FullArrowRight } from 'assets/bosta-icons/Arrow-right.svg';

import '../../SignUp.less';

const PricingContainer = ({
  activePlan,
  setActivePlan,
  intl,
  stepperCurrentStep,
  footerRef,
  mobileScreenSizes: { isSmallMobileScreen },
  setCashoutType,
  hideLoadingSpinner = false,
  setIsPricingPlanLoading,
  isSllrActivation = false
}) => {
  const [loading, setloading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [pricingData, setPricingData] = useState([]);
  const [businessPlanData, setBusinessPlanData] = useState(null);
  const [basicPlanData, setBasicPlanData] = useState(null);
  const [startingFromBusiness, setStartingFromBusiness] = useState('');
  const [startingFromBasic, setStartingFromBasic] = useState('');
  const [cityFrom, setCityFrom] = useState();
  const [cityTo, setCityTo] = useState();
  const [vatIncluded, setVatIncluded] = useState(false);
  const [nextDayFeesBusiness, setNextDayFeesBusiness] = useState('');

  const { updateBusinessInfo } = useContext(ContextWrapper);
  const { openModal } = useModal();

  const pricingTierIds = JSON.parse(localStorage.getItem('userInfo'))?.user
    ?.country?.pricingSignUpTiers;

  const columns = [
    {
      title: intl.formatMessage({
        id: 'settings.pricing_plan.package_sizes'
      }),
      dataIndex: 'name',
      key: 'name',
      className: 'br-signup-pricing__city-table-header'
    },
    {
      title: (
        <div className="br-pricing-plan__table-header-title">
          {intl.formatMessage({
            id: 'settings.pricing_plan.table_columns.deliver'
          })}
        </div>
      ),
      dataIndex: 'deliver',
      key: 'deliver'
    },
    {
      title: (
        <div className="br-pricing-plan__table-header-title">
          {intl.formatMessage({
            id: 'settings.pricing_plan.table_columns.exchange'
          })}
        </div>
      ),
      dataIndex: 'exchange',
      key: 'exchange'
    },
    {
      title: (
        <div className="br-pricing-plan__table-header-title">
          {intl.formatMessage({
            id: 'settings.pricing_plan.table_columns.return'
          })}
        </div>
      ),
      dataIndex: 'return',
      key: 'return'
    },
    {
      title: (
        <div className="br-pricing-plan__table-header-title">
          {intl.formatMessage({
            id: 'settings.pricing_plan.table_columns.cash_collection'
          })}
        </div>
      ),
      dataIndex: 'cash_collection',
      key: 'cash_collection'
    },
    {
      title: (
        <div className="br-pricing-plan__table-header-title">
          {intl.formatMessage({
            id: 'settings.pricing_plan.table_columns.rto'
          })}
        </div>
      ),
      dataIndex: 'rto',
      key: 'rto'
    }
  ];

  const featuresSectionRef = useRef();
  const citiesToRef = useRef();
  const scrollHeaderRef = useRef();

  useEffect(() => {
    if (!isSllrUser()) {
      sendSegment('Step3_Price-Plan-Landing');
    }
    setActivePlan(pricingTierIds?.basicTierId);
    setCityTo(PICKUP_LOCATION_OPTIONS[1]);
    setCityFrom(PICKUP_LOCATION_OPTIONS[1]);
    loadPriceTiersList();
  }, []);

  useEffect(() => {
    cityFrom &&
      updatePricingData(cityFrom?.key, vatIncluded, cityTo?.key, activePlan);

    (basicPlanData || businessPlanData) &&
      setCashoutType?.(
        intl.formatMessage({
          id:
            isNextDayDelivery(
              businessPlanData,
              pricingTierIds?.businessTierId
            ) || isNextDayDelivery(basicPlanData, pricingTierIds?.basicTierId)
              ? 'sign_up.pricing.instant'
              : 'pickups.table_columns.repeatation_types.weekly'
        })
      );
  }, [activePlan]);

  useEffect(() => {
    !loading && intersectionObserver();
  }, [loading]);

  const intersectionObserver = () => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (scrollHeaderRef.current) {
          scrollHeaderRef.current.style.display = 'none';
          if (entry.isIntersecting) {
            scrollHeaderRef.current.style.display = 'flex';
          }
        }
      });
    });
    featuresSectionRef?.current &&
      observer.observe(featuresSectionRef?.current);
  };

  const loadPriceTiersList = async () => {
    setloading(true);
    setIsPricingPlanLoading?.(true);
    if (footerRef) {
      footerRef.current.style.display = 'none';
    }
    try {
      const businessPlanResponse = await getSignupPricingInfo({
        pickupSectorId: isSaudi() ? 100 : 1,
        vatIncluded: false,
        dropoffSectorId: isSaudi() ? 100 : 1,
        tierIdSelector: pricingTierIds?.businessTierId,
        ...(isSllrActivation && { isSllrActivation: true })
      });
      const basicPlanResponse = await getSignupPricingInfo({
        pickupSectorId: isSaudi() ? 100 : 1,
        vatIncluded: false,
        dropoffSectorId: isSaudi() ? 100 : 1,
        tierIdSelector: pricingTierIds?.basicTierId,
        ...(isSllrActivation && { isSllrActivation: true })
      });
      setBusinessPlanData(businessPlanResponse);
      setBasicPlanData(basicPlanResponse);

      setPricingData(businessPlanResponse.prices);

      setStartingFromBusiness(
        businessPlanResponse?.prices[0]?.tierServiceTypes
          .filter((obj) => {
            return obj.typeName === SEND;
          })[0]
          .tierSizes?.filter((obj, id) => {
            return isSaudi() ? id === 0 : obj.sizeName === NORMAL;
          })[0].cost
      );
      setNextDayFeesBusiness(
        businessPlanResponse?.tier?.expediteFee?.percentage * 100
      );
      setStartingFromBasic(
        basicPlanResponse?.prices[0]?.tierServiceTypes
          .filter((obj) => {
            return obj.typeName === SEND;
          })[0]
          .tierSizes?.filter((obj, id) => {
            return isSaudi() ? id === 0 : obj.sizeName === NORMAL;
          })[0].cost
      );
    } catch (error) {
      notify({
        msg: error.message,
        type: 'error'
      });
    }
    if (footerRef) {
      footerRef.current.style.display = 'block';
    }
    setloading(false);
    setIsPricingPlanLoading?.(false);
  };

  const isNextDayDelivery = (data, plan) => {
    return (
      data?.tier?.configurations?.paymentFrequency ===
        PAYMENT_FREQUENCY.NEXT_DAY && activePlan === plan
    );
  };

  const hasCODfees = (data) => {
    return data?.tier?.configurations?.extraCodFee;
  };

  const getCODfees = (plan) => {
    switch (plan) {
      case pricingTierIds?.businessTierId:
        return businessPlanData?.tier?.extraCodFee.percentage * 100;
      case pricingTierIds?.basicTierId:
        return basicPlanData?.tier?.extraCodFee.percentage * 100;
      default:
        break;
    }
  };

  const getCODAmount = (plan) => {
    switch (plan) {
      case pricingTierIds?.businessTierId:
        return businessPlanData?.tier?.extraCodFee.codAmount;
      case pricingTierIds?.basicTierId:
        return basicPlanData?.tier?.extraCodFee.codAmount;
      default:
        break;
    }
  };

  const hasNextDayfees = (data) => {
    return data?.tier?.configurations?.expediteFee;
  };

  const getNextDayfees = (plan) => {
    switch (plan) {
      case pricingTierIds?.businessTierId:
        return businessPlanData?.tier?.expediteFee.percentage * 100;
      case pricingTierIds?.basicTierId:
        return basicPlanData?.tier?.expediteFee.percentage * 100;
      default:
        break;
    }
  };

  const hasInsuranceFee = (data, plan) => {
    return data?.tier?.configurations?.insuranceFee && activePlan === plan;
  };

  const getInsuranceFee = (activePlan) => {
    switch (activePlan) {
      case pricingTierIds?.businessTierId:
        return businessPlanData?.tier?.insuranceFee.percentage * 100;
      case pricingTierIds?.basicTierId:
        return basicPlanData?.tier?.insuranceFee.percentage * 100;
      default:
        break;
    }
  };

  const updatePricingData = async (
    cityFromKey,
    vatIncluded,
    cityToKey,
    plan,
    callback = () => {}
  ) => {
    setTableLoading(true);
    try {
      const payload = {
        pickupSectorId: cityFromKey,
        vatIncluded: vatIncluded,
        dropoffSectorId: cityToKey,
        tierIdSelector: plan,
        ...(isSllrActivation && { isSllrActivation: true })
      };
      const { prices } = await getSignupPricingInfo(payload);
      setPricingData(prices);
      callback();
    } catch (error) {
      notify({
        msg: error.message,
        type: 'error'
      });
    }
    setTableLoading(false);
  };

  const onToggleVAT = async (toggleState) => {
    updatePricingData(cityFrom?.key, toggleState, cityTo?.key, activePlan, () =>
      setVatIncluded(toggleState)
    );
  };

  const scroll = (scrollOffset) => {
    citiesToRef.current.scrollLeft += scrollOffset;
  };

  const areaOption = (area, direction) => {
    return (
      <Select.Option key={area.key} value={area.zone} label={area.zone}>
        <div className="br-signup-pricing__select-options">
          {isSmallMobileScreen && (
            <span className="br-area__title-span subheading">
              {direction === PICKUP
                ? intl.formatMessage({
                    id: 'orders.order_filter.from'
                  })
                : intl.formatMessage({
                    id: 'orders.order_filter.to'
                  })}
            </span>
          )}
          <span className="br-area__first-span">{area?.zone}</span>
          <span className="br-area__second-span caption-lg">{area?.label}</span>
        </div>
      </Select.Option>
    );
  };

  const renderCitiesSection = () => {
    return (
      <>
        <div className="br-signup__pricing-city-select">
          {!isSmallMobileScreen && (
            <span className="br-signup__city-from-span subheading">
              {intl.formatMessage({
                id: 'orders.order_filter.from'
              })}
            </span>
          )}
          <Select
            listHeight={308}
            dropdownMatchSelectWidth={300}
            defaultValue={cityFrom?.zone}
            value={cityFrom?.zone}
            dropdownClassName="br-signup-pricing__cities-dropdown"
            virtual={false}
            onChange={
              isSaudi()
                ? () => {}
                : (value, option) => {
                    updatePricingData(
                      parseInt(option.key),
                      vatIncluded,
                      cityTo?.key,
                      activePlan,
                      () => setCityFrom(PICKUP_LOCATION_OPTIONS[option.key])
                    );
                  }
            }
          >
            {Object.keys(PICKUP_LOCATION_OPTIONS).map((location) =>
              areaOption(PICKUP_LOCATION_OPTIONS[location], PICKUP)
            )}
          </Select>
        </div>

        {!isSmallMobileScreen ? (
          <div className="br-signup__pricing-dropoff-cities">
            <span className="br-signup__dropoff-city-span subheading">
              {intl.formatMessage({
                id: 'orders.order_filter.to'
              })}
            </span>
            <BRButton
              type="link-gray"
              className="br-signup__pricing-dropoff__scroll-button"
              onClick={() => scroll(-50)}
              suffixIcon={<Icon component={ArrowLeft} />}
            />
            <div
              className="br-signup__dropoff-cities__buttons-container"
              ref={citiesToRef}
            >
              {Object.keys(PICKUP_LOCATION_OPTIONS).map((location) => (
                <BRButton
                  type="outline"
                  className={classnames('br-signup__dropoff-cities__button', {
                    'br-signup__active-city-to-button body-medium':
                      cityTo === PICKUP_LOCATION_OPTIONS[location]
                  })}
                  key={PICKUP_LOCATION_OPTIONS[location]?.key}
                  label={PICKUP_LOCATION_OPTIONS[location]?.zone}
                  onClick={() =>
                    updatePricingData(
                      cityFrom?.key,
                      vatIncluded,
                      PICKUP_LOCATION_OPTIONS[location]?.key,
                      activePlan,
                      () => setCityTo(PICKUP_LOCATION_OPTIONS[location])
                    )
                  }
                />
              ))}
            </div>
            <BRButton
              type="link-gray"
              className="br-signup__pricing-dropoff__scroll-button"
              onClick={() => scroll(50)}
              suffixIcon={<Icon component={ArrowRight} />}
            />
          </div>
        ) : (
          <div className="br-signup__pricing-city-select">
            <Select
              listHeight={308}
              dropdownMatchSelectWidth={300}
              defaultValue={cityTo?.zone}
              value={cityTo?.zone}
              dropdownClassName="br-signup-pricing__cities-dropdown"
              virtual={false}
              onChange={(value, option) => {
                updatePricingData(
                  cityFrom?.key,
                  vatIncluded,
                  parseInt(option.key),
                  activePlan,
                  () => setCityTo(PICKUP_LOCATION_OPTIONS[option.key])
                );
              }}
            >
              {Object.keys(PICKUP_LOCATION_OPTIONS).map((location) =>
                areaOption(PICKUP_LOCATION_OPTIONS[location], 'dropoff')
              )}
            </Select>
          </div>
        )}
      </>
    );
  };

  const onFixedHeaderChange = (e) => {
    setActivePlan(e.target.value);
  };

  const handleOnCashoutGuideClick = () => {
    sendSegment('Open_Cashout_Modal', {
      Source: isSllrActivation
        ? 'Sllr Activation - Pricing Page'
        : 'Bosta Signup - Pricing Page'
    });
    openModal(CashoutGuideModal, {
      isSignUp: true,
      updateBusinessInfo
    });
  };

  return loading ? (
    <div className="br-signup__pricing_loading">
      {!hideLoadingSpinner && <Spin indicator={<LoadingOutlined spin />} />}
    </div>
  ) : (
    <div className="br-signup__pricing-container">
      <div>
        {stepperCurrentStep && (
          <p className="br-signup__services-steps subheading">
            {intl.formatMessage(
              {
                id: 'sign_up.services.steps'
              },
              {
                currentStep: `${stepperCurrentStep + 1}`,
                lastStep: STEPPER.LAST_STEP
              }
            )}
          </p>
        )}
        <span className="display-md">
          {intl.formatMessage({ id: 'sign_up.pricing.title' })}
        </span>
        <p className="br-signup__pricing-subtitle">
          {intl.formatMessage({
            id: 'sign_up.pricing.subtitle'
          })}
        </p>
        <div className="br-signup__table-container">
          <div className="br-signup__table-container__row">
            <div className="br-signup__first-row-title-column br-signup__title-column">
              <div className="body-medium">
                {intl.formatMessage({
                  id: 'sign_up.pricing.plans'
                })}
              </div>
              <div className="body-medium">
                {intl.formatMessage({
                  id: 'sign_up.terms_modal.pricing_title'
                })}
              </div>
            </div>
            <div className="br-signup__first-row-plans">
              {isInstantCashoutPlanAvailable() && (
                <PricingPlanCard
                  activePlan={activePlan}
                  setActivePlan={setActivePlan}
                  active={activePlan === pricingTierIds?.businessTierId}
                  plan={pricingTierIds?.businessTierId}
                  title={intl.formatMessage({
                    id: 'sign_up.pricing.business'
                  })}
                  cashout={
                    <span>
                      {intl.formatMessage(
                        {
                          id: 'sign_up.pricing.cashouts'
                        },
                        {
                          value: intl.formatMessage({
                            id: 'sign_up.pricing.instant'
                          })
                        }
                      )}
                      ⚡
                    </span>
                  }
                  description={intl.formatMessage({
                    id: 'sign_up.pricing.instant_description'
                  })}
                  startingFromValue={startingFromBusiness}
                  showNextDayfeesUnderPrice
                  nextDayfees={nextDayFeesBusiness}
                  plans={pricingTierIds}
                />
              )}
              <PricingPlanCard
                activePlan={activePlan}
                setActivePlan={setActivePlan}
                active={activePlan === pricingTierIds?.basicTierId}
                plan={pricingTierIds?.basicTierId}
                title={intl.formatMessage({
                  id: 'sign_up.pricing.basic'
                })}
                cashout={intl.formatMessage(
                  {
                    id: 'sign_up.pricing.cashouts'
                  },
                  {
                    value: intl.formatMessage({
                      id: 'pickups.table_columns.repeatation_types.weekly'
                    })
                  }
                )}
                description={intl.formatMessage({
                  id: 'sign_up.pricing.weekly_description'
                })}
                startingFromValue={startingFromBasic}
                plans={pricingTierIds}
              />
            </div>
          </div>
          <div className="br-signup__more-than-row-container">
            <div className="br-signup__more-than-row">
              <span>
                {intl.formatMessage({
                  id: 'sign_up.pricing.3k_plus'
                })}
              </span>
              <span>
                {intl.formatMessage({
                  id: 'sign_up.pricing.3k_plus_description'
                })}
              </span>
            </div>
          </div>
          <div className="br-signup__info-row">
            <span className="br-signup__title heading">
              {intl.formatMessage({
                id: 'sign_up.pricing.features'
              })}
            </span>
            <div className="br-signup__table-container__row">
              <div className="br-signup__info-row-title-column br-signup__title-column with-cashout-guide">
                <span>
                  {intl.formatMessage({
                    id: 'sign_up.pricing.shipping_overnight'
                  })}
                </span>
                <span>
                  {intl.formatMessage({
                    id: 'sign_up.pricing.insurance_coverage'
                  })}
                </span>
                <span>
                  {intl.formatMessage({
                    id: 'activation_pricing.cashout_frequency'
                  })}
                </span>
              </div>
              <div className="br-signup__info-row-plans with-cashout-guide">
                <div>
                  {isInstantCashoutPlanAvailable() && (
                    <div
                      className={classnames('br-signup__info-row-plan-column', {
                        active: activePlan === pricingTierIds?.businessTierId
                      })}
                    >
                      <span>
                        <Check />
                      </span>
                      <span>
                        {businessPlanData?.tier?.configurations?.insuranceFee
                          ? intl.formatMessage({
                              id: 'sign_up.pricing.free'
                            })
                          : intl.formatMessage({
                              id: 'sign_up.pricing.not_applied'
                            })}
                      </span>
                      <span>
                        {intl.formatMessage({
                          id: 'sign_up.pricing.next_day'
                        })}
                      </span>
                    </div>
                  )}
                  <div
                    className={classnames('br-signup__info-row-plan-column', {
                      active: activePlan === pricingTierIds?.basicTierId
                    })}
                  >
                    <span>
                      <Check />
                    </span>
                    <span
                      className={classnames({
                        'br-signup__not-applied':
                          isInstantCashoutPlanAvailable()
                      })}
                    >
                      {basicPlanData?.tier?.configurations?.insuranceFee
                        ? intl.formatMessage({
                            id: 'sign_up.pricing.free'
                          })
                        : intl.formatMessage({
                            id: 'sign_up.pricing.not_applied'
                          })}
                    </span>
                    <span className="br-signup__frequency">
                      {intl.formatMessage({
                        id: 'pickups.table_columns.repeatation_types.weekly'
                      })}{' '}
                      <span>
                        (
                        {intl.formatMessage({
                          id: 'pickups.repeated_modal.days.wednesday'
                        })}
                        )
                      </span>
                    </span>
                  </div>
                </div>
                {!isSmallMobileScreen && isInstantCashoutPlanAvailable() && (
                  <div className="br-pricing-container__cashout-guide-modal__container">
                    <span className="heading">
                      {intl.formatMessage({
                        id: 'cash_out_guide.sign_up.title'
                      })}
                    </span>
                    <BRButton
                      label={intl.formatMessage({
                        id: 'cash_out_guide.sign_up.button_text'
                      })}
                      suffixIcon={<FullArrowRight />}
                      type="outline"
                      onClick={handleOnCashoutGuideClick}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {isSmallMobileScreen && isInstantCashoutPlanAvailable() && (
            <div className="br-signup__info-row">
              <div className="br-pricing-container__cashout-guide-modal__container">
                <span className="heading">
                  {intl.formatMessage({
                    id: 'cash_out_guide.sign_up.title'
                  })}
                </span>
                <BRButton
                  label={intl.formatMessage({
                    id: 'cash_out_guide.sign_up.button_text'
                  })}
                  suffixIcon={<FullArrowRight />}
                  type="outline"
                  onClick={handleOnCashoutGuideClick}
                />
              </div>
            </div>
          )}

          <div className="br-signup__info-row">
            <span className="br-signup__title heading">
              {intl.formatMessage({
                id: 'sign_up.pricing.additional_fees'
              })}
            </span>
            <div className="br-signup__table-container__row">
              <div className="br-signup__info-row-title-column br-signup__title-column">
                <span>
                  {intl.formatMessage({
                    id: 'sign_up.pricing.cash_collection_fees'
                  })}
                </span>
                <span>
                  {intl.formatMessage({
                    id: 'sign_up.pricing.next_day_transfer_fees'
                  })}
                </span>
              </div>
              <div className="br-signup__info-row-plans">
                {isInstantCashoutPlanAvailable() && (
                  <div
                    className={classnames(
                      'br-signup__info-row-plan-column',
                      'br-signup__plan-column',
                      {
                        active: activePlan === pricingTierIds?.businessTierId
                      }
                    )}
                  >
                    <span
                      className={
                        hasCODfees(businessPlanData)
                          ? 'br-signup__percentage'
                          : 'br-signup__not-applied'
                      }
                    >
                      <Tooltip
                        placement="right"
                        title={
                          hasCODfees(businessPlanData)
                            ? intl.formatMessage(
                                {
                                  id: 'sign_up.pricing.cash_on_delivery_tooltip'
                                },
                                {
                                  amount: 3000,
                                  value:
                                    businessPlanData?.tier?.extraCodFee
                                      ?.percentage * 100
                                }
                              )
                            : ''
                        }
                      >
                        <span>
                          {hasCODfees(businessPlanData)
                            ? `${
                                businessPlanData?.tier?.extraCodFee
                                  ?.percentage * 100
                              }%`
                            : intl.formatMessage({
                                id: 'sign_up.pricing.not_applied'
                              })}
                        </span>
                      </Tooltip>
                    </span>
                    <span
                      className={
                        hasNextDayfees(businessPlanData)
                          ? 'br-signup__percentage'
                          : 'br-signup__not-applied'
                      }
                    >
                      <Tooltip
                        placement="right"
                        title={
                          hasNextDayfees(businessPlanData)
                            ? intl.formatMessage(
                                {
                                  id: 'sign_up.pricing.next_day_tooltip'
                                },
                                {
                                  value:
                                    businessPlanData?.tier?.expediteFee
                                      ?.percentage * 100
                                }
                              )
                            : ''
                        }
                      >
                        <span>
                          {hasNextDayfees(businessPlanData)
                            ? `${
                                businessPlanData?.tier?.expediteFee
                                  ?.percentage * 100
                              }%`
                            : intl.formatMessage({
                                id: 'sign_up.pricing.not_applied'
                              })}
                        </span>
                      </Tooltip>
                    </span>
                  </div>
                )}
                <div
                  className={classnames(
                    'br-signup__info-row-plan-column',
                    'br-signup__plan-column',
                    {
                      active: activePlan === pricingTierIds?.basicTierId
                    }
                  )}
                >
                  <span
                    className={
                      hasCODfees(basicPlanData)
                        ? 'br-signup__percentage'
                        : 'br-signup__not-applied'
                    }
                  >
                    <Tooltip
                      placement="right"
                      title={
                        hasCODfees(basicPlanData)
                          ? intl.formatMessage(
                              {
                                id: 'sign_up.pricing.cash_on_delivery_tooltip'
                              },
                              {
                                amount: 3000,
                                value:
                                  basicPlanData?.tier?.extraCodFee?.percentage *
                                  100
                              }
                            )
                          : ''
                      }
                    >
                      <span>
                        {hasCODfees(basicPlanData)
                          ? `${
                              basicPlanData?.tier?.extraCodFee?.percentage * 100
                            }%`
                          : intl.formatMessage({
                              id: 'sign_up.pricing.not_applied'
                            })}
                      </span>
                    </Tooltip>
                  </span>
                  <span
                    className={
                      hasNextDayfees(basicPlanData)
                        ? 'br-signup__percentage'
                        : 'br-signup__not-applied'
                    }
                  >
                    <Tooltip
                      placement="right"
                      title={
                        hasNextDayfees(basicPlanData)
                          ? intl.formatMessage(
                              {
                                id: 'sign_up.pricing.next_day_tooltip'
                              },
                              {
                                value:
                                  basicPlanData?.tier?.expediteFee?.percentage *
                                  100
                              }
                            )
                          : ''
                      }
                    >
                      <span>
                        {hasNextDayfees(basicPlanData)
                          ? `${
                              basicPlanData?.tier?.expediteFee?.percentage * 100
                            }%`
                          : intl.formatMessage({
                              id: 'sign_up.pricing.not_applied'
                            })}
                      </span>
                    </Tooltip>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSaudi() && (
        <div
          className="br-signup__pricing-container__pricing-section"
          ref={featuresSectionRef}
        >
          <PricingCalculatorBanner />
        </div>
      )}
      {!isSaudi() && (
        <div
          className="br-signup__pricing-container__pricing-section"
          ref={featuresSectionRef}
        >
          <PricingCalculatorBanner />
          <div className="br-signup__vat-section">
            <div className="br-signup__vat-section__plan-selected">
              <span className="heading">
                {intl.formatMessage({
                  id: 'sign_up.pricing.selected_plan'
                })}
              </span>
              <span>
                {intl.formatMessage(
                  {
                    id: 'settings.pricing_plan.vat.vat_14_sub_title'
                  },
                  {
                    vatValue: parseInt(
                      businessPlanData?.tier?.country?.vat * 100
                    )
                  }
                )}
              </span>
            </div>
            <div className="br-signup__vat-toggle-container">
              <span>{intl.formatMessage({ id: 'sign_up.pricing.vat' })}</span>
              <Switch
                size="small"
                loading={tableLoading}
                onChange={onToggleVAT}
              />
            </div>
          </div>
          <div className="br-signup__pricing-table">
            <Table
              columns={columns}
              size="medium"
              pagination={false}
              dataSource={formatPricingTableData(pricingData, false, true)}
              scroll={{ x: true }}
              loading={tableLoading}
              title={renderCitiesSection}
            />
          </div>
          <div className="br-signup__pricing-other-revenue-section">
            {((hasNextDayfees(basicPlanData) &&
              activePlan === pricingTierIds?.basicTierId) ||
              (hasNextDayfees(businessPlanData) &&
                activePlan === pricingTierIds?.businessTierId)) && (
              <div className="br-signup__other-revenue">
                <p className="body-medium br-signup__other-revenue-section__header">
                  {intl.formatMessage({
                    id: 'sign_up.pricing.next_day_transfer_fees'
                  })}
                </p>
                <span className="br-signup__other-revenue-section__description">
                  {intl.formatMessage(
                    {
                      id: 'sign_up.pricing.next_day_tooltip'
                    },
                    {
                      value: getNextDayfees(activePlan)
                    }
                  )}
                </span>
              </div>
            )}
            {((hasCODfees(basicPlanData) &&
              activePlan === pricingTierIds?.basicTierId) ||
              (hasCODfees(businessPlanData) &&
                activePlan === pricingTierIds?.businessTierId)) && (
              <div className="br-signup__other-revenue">
                <p className="body-medium br-signup__other-revenue-section__header">
                  {intl.formatMessage({
                    id: 'sign_up.pricing.cash_collection_fees'
                  })}
                </p>
                <span className="br-signup__other-revenue-section__description">
                  {intl.formatMessage(
                    {
                      id: 'sign_up.pricing.cash_on_delivery_tooltip'
                    },
                    {
                      amount: getCODAmount(activePlan),
                      value: getCODfees(activePlan)
                    }
                  )}
                </span>
              </div>
            )}
            {(hasInsuranceFee(basicPlanData, pricingTierIds?.basicTierId) ||
              hasInsuranceFee(
                businessPlanData,
                pricingTierIds?.businessTierId
              )) && (
              <div className="br-signup__other-revenue">
                <p className="body-medium br-signup__other-revenue-section__header">
                  {intl.formatMessage({
                    id: 'sign_up.pricing.insurance'
                  })}
                </p>
                <span className="br-signup__other-revenue-section__description">
                  {intl.formatMessage(
                    {
                      id:
                        getInsuranceFee(activePlan) === 0
                          ? 'sign_up.pricing.insurance_covered'
                          : 'sign_up.pricing.insurance_description'
                    },
                    {
                      value: getInsuranceFee(activePlan)
                    }
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
      )}

      {isSmallMobileScreen && (
        <Radio.Group
          className="br-signup-pricing__scroll-header"
          value={activePlan}
          onChange={onFixedHeaderChange}
          ref={scrollHeaderRef}
        >
          {isInstantCashoutPlanAvailable() && (
            <Radio
              className={classnames('br-signup__scroll-header-business', {
                active: activePlan === pricingTierIds?.businessTierId
              })}
              value={pricingTierIds?.businessTierId}
            >
              <span className="subheading">
                {intl.formatMessage(
                  {
                    id: 'sign_up.pricing.cashouts'
                  },
                  {
                    value: intl.formatMessage({
                      id:
                        businessPlanData?.tier?.configurations
                          ?.paymentFrequency === PAYMENT_FREQUENCY.NEXT_DAY
                          ? 'sign_up.pricing.instant'
                          : 'pickups.table_columns.repeatation_types.weekly'
                    })
                  }
                )}
              </span>
              <div>
                <span className="display-sm">
                  {intl.formatMessage(
                    { id: 'sign_up.pricing.starting_from_value' },
                    { value: startingFromBusiness }
                  )}
                </span>
                <span className="br-signup__per-order">
                  {' '}
                  {intl.formatMessage({
                    id: 'sign_up.pricing.per_order'
                  })}
                </span>
              </div>
            </Radio>
          )}
          <Radio
            className={classnames('br-signup__scroll-header-basic', {
              active: activePlan === pricingTierIds?.basicTierId
            })}
            value={pricingTierIds?.basicTierId}
          >
            <span className="subheading">
              {intl.formatMessage(
                {
                  id: 'sign_up.pricing.cashouts'
                },
                {
                  value: intl.formatMessage({
                    id:
                      basicPlanData?.tier?.configurations?.paymentFrequency ===
                      PAYMENT_FREQUENCY.NEXT_DAY
                        ? 'sign_up.pricing.instant'
                        : 'pickups.table_columns.repeatation_types.weekly'
                  })
                }
              )}
            </span>
            <div>
              <span className="display-sm">
                {intl.formatMessage(
                  {
                    id: COUNTRY_CURRENCY
                  },
                  {
                    cod: startingFromBusiness
                  }
                )}
              </span>
              <span className="br-signup__per-order">
                {intl.formatMessage({
                  id: 'sign_up.pricing.per_order'
                })}
              </span>
            </div>
          </Radio>
        </Radio.Group>
      )}
    </div>
  );
};
export default injectIntl(mediaHook(PricingContainer));
