import isEmpty from 'lodash/isEmpty';

import { getLocaleFromLocalStorage } from 'utils/intl-wrapper';
import { LOCALE } from 'constants/intl-wrapper';
import {
  ALLOWED_STATES_FOR_BUSINESS_EDIT_ORDER,
  INITIAL_STATES,
  DELIVERY_TYPES_CODES,
  PACKAGE_TYPES,
  PUDO_HUB_TYPE,
  PUDO_PROVIDER,
  ALLOWED_STATES_TO_CANCEL_ORDER
} from 'constants/shipments';
import { ORDER_TYPES } from './orders';

import { fmt } from 'components/IntlWrapper/IntlWrapper';

import { ReactComponent as DeliverIcon } from 'assets/imgRevamp/DeliverIcon.svg';
import { ReactComponent as ExchangeIcon } from 'assets/imgRevamp/ExchangeIcon.svg';
import { ReactComponent as ReturnIcon } from 'assets/imgRevamp/ReturnIcon.svg';
import { ReactComponent as CashCollectionIcon } from 'assets/imgRevamp/CashCollectionIcon.svg';
import { INTERNATIONAL_ORDER_STATUS_CODES } from 'constants/international-orders';

const shipmentStatus = [
  { icon: ExchangeIcon, code: 30 },
  { icon: CashCollectionIcon, code: 15 },
  { icon: DeliverIcon, code: 10 },
  { icon: ReturnIcon, code: 20 },
  { icon: ReturnIcon, code: 25 }
];

export const mapStateToStatus = (state) => {
  return Object.values(shipmentStatus).find((item) => item.code === state)
    ?.icon;
};

export const isEditableOrder = (
  order,
  allowInTransitEditing = true,
  isDraftOrder = false
) => {
  if (isEmpty(order)) {
    return;
  }

  if (isDraftOrder) {
    return true;
  }

  if (order?.isInternationalOrder) {
    return order.state?.code === INTERNATIONAL_ORDER_STATUS_CODES.CREATED;
  }

  const { state, integrationInfo } = order;

  const isInitialStateOrder = INITIAL_STATES.includes(state.code);

  const isSendOrderAndEditable =
    order.type.code === DELIVERY_TYPES_CODES.SEND &&
    ALLOWED_STATES_FOR_BUSINESS_EDIT_ORDER.includes(state.code);

  const isExchangeForwardOrder =
    order.type.code === DELIVERY_TYPES_CODES.EXCHANGE &&
    ALLOWED_STATES_FOR_BUSINESS_EDIT_ORDER.includes(state.code) &&
    !order.collectedFromConsignee;

  const isEditPudo =
    state?.receivedAtWarehouse?.warehouse?.type !== PUDO_HUB_TYPE ||
    ![41, 45, 46].includes(state?.code) ||
    isSendOrderAndEditable ||
    isInitialStateOrder;

  return integrationInfo?.provider === PUDO_PROVIDER
    ? isEditPudo
    : isInitialStateOrder ||
        (allowInTransitEditing &&
          (isSendOrderAndEditable || isExchangeForwardOrder));
};

export const formatAreaZone = (address) => {
  const { zone, district } = address;
  let formatedArea = '';

  if (!zone?.name && !district) {
    return (formatedArea = fmt({
      id: `common.empty_field`
    }));
  } else if (typeof district === 'string') {
    if (getLocaleFromLocalStorage() === LOCALE.AR) {
      if (
        zone &&
        (zone?.name === district || zone?.nameAr === district || !district)
      ) {
        formatedArea = `${zone?.nameAr ? zone?.nameAr : zone?.name}`;
      } else {
        formatedArea = `${
          zone?.nameAr ? zone?.nameAr : zone?.name
        } - ${district}`;
      }
    } else {
      if (zone && (zone?.name === district || !district)) {
        formatedArea = `${zone?.name}`;
      } else {
        formatedArea = `${zone?.name} - ${district}`;
      }
    }
  } else {
    if (getLocaleFromLocalStorage() === LOCALE.AR) {
      formatedArea =
        zone && zone?.name !== district?.name
          ? district
            ? `${zone?.nameAr ? zone?.nameAr : zone?.name} - ${
                district?.nameAr ? district?.nameAr : district?.name
              }`
            : `${zone?.nameAr ? zone?.nameAr : zone?.name}`
          : `${district?.nameAr ? district?.nameAr : district?.name}`;
    } else {
      formatedArea =
        zone && zone?.name !== district?.name
          ? district
            ? `${zone?.name} -${district?.name || ''}`
            : `${zone?.name}`
          : `${district?.name}`;
    }
  }
  return formatedArea;
};

export const getOrderAddress = (order) => {
  // 15 CASH_COLLECTION, 25 CUSTOMER_RETURN_PICKUP
  const address =
    order.type.code === DELIVERY_TYPES_CODES.CASH_COLLECTION ||
    order.type.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP ||
    order.type.code === DELIVERY_TYPES_CODES.SIGN_AND_RETURN
      ? order?.pickupAddress
      : order?.dropOffAddress;
  const city =
    getLocaleFromLocalStorage() === LOCALE.EN
      ? address?.city?.name
      : address?.city?.nameAr;
  const area = formatAreaZone(address);
  const zone =
    getLocaleFromLocalStorage() === LOCALE.AR && address.zone?.nameAr
      ? address?.zone?.nameAr
      : address?.zone?.name;
  const district =
    getLocaleFromLocalStorage() === LOCALE.AR && address.district?.nameAr
      ? address?.district?.nameAr
      : address?.district?.name;

  return {
    fullAddress: address,
    city,
    area,
    zone,
    district
  };
};

export const checkPackageType = (item) => {
  if (item.type?.code === DELIVERY_TYPES_CODES.SIGN_AND_RETURN) {
    return fmt({
      id: `orders.order_types.sign_and_return`
    });
  } else if (item?.specs?.packageType !== PACKAGE_TYPES[0].value)
    return fmt({
      id: `orders.package_type.${item?.specs?.packageType
        ?.replace(/\s+/g, '_')
        .toLowerCase()}`
    });
};

export const formatSearchInput = (value) => {
  const inputs = value
    .trim()
    .replace(/,+\s*$/, '')
    .split(/[, ]+/);

  const formattedValues = [];

  inputs.map((input) => {
    input = input.trim();
    formattedValues.push(input);
  });

  return formattedValues.length ? formattedValues.join(',') : null;
};

export const isForwardOrder = (order) => {
  const {
    SEND,
    FXF_SEND,
    CASH_COLLECTION,
    CUSTOMER_RETURN_PICKUP,
    EXCHANGE,
    SIGN_AND_RETURN
  } = DELIVERY_TYPES_CODES;
  const { type, collectedFromConsignee } = order;

  return (
    [SEND, FXF_SEND].includes(type.code) ||
    ([
      CASH_COLLECTION,
      CUSTOMER_RETURN_PICKUP,
      EXCHANGE,
      SIGN_AND_RETURN
    ].includes(type.code) &&
      !collectedFromConsignee)
  );
};

export const isPickupOrder = (order) => {
  const { CASH_COLLECTION, CUSTOMER_RETURN_PICKUP } = DELIVERY_TYPES_CODES;
  const { type } = order;

  return [CASH_COLLECTION, CUSTOMER_RETURN_PICKUP].includes(type.code);
};

export const calculateOrdersSuccessRate = (totalOrders, successOrders) => {
  return totalOrders ? Math.round((successOrders / totalOrders) * 100) : 0;
};

export const isCancelableOrder = (order) => {
  if (isEmpty(order)) {
    return;
  }

  const { state, isFulfillmentOrder, type, isBostaFulfillmentOrder } = order;

  const isStateAllowedToCancel = ALLOWED_STATES_TO_CANCEL_ORDER.includes(
    state.code
  );

  const bostaFulfillmentCondition =
    type.value === ORDER_TYPES.fxf_send &&
    isBostaFulfillmentOrder &&
    isStateAllowedToCancel;

  return (
    (isForwardOrder(order) &&
      !isPickupOrder(order) &&
      type.code !== DELIVERY_TYPES_CODES.SIGN_AND_RETURN &&
      !isFulfillmentOrder &&
      isStateAllowedToCancel) ||
    INITIAL_STATES.includes(state.code) ||
    bostaFulfillmentCondition
  );
};
