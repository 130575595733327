import React, { useState, useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input, Select } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import { withRouter } from 'react-router-dom';
import querySearch from 'stringquery';

import {
  MARKETING_CHANNELS,
  ERROR_CODES,
  DASHBOARD_REG_SRC,
  SLLR_REG_SRC
} from 'constants/signup';
import { requiredRule, emailRule, fullNameRule } from 'utils/forms';
import { TEXT } from 'constants/Forms';
import { useModal } from 'contexts/modalProvider.context';
import { sendSegment } from 'utils/segment';
import { getSignInPath } from 'utils/login';
import { isSllrUser, getAccountTypePropForSuccessMetrics } from 'utils/helpers';
import { register, sllrRegister } from 'services/auth';
import { COUNTRIES } from 'constants/country-data';

import { notify } from 'components/Notify/Notify';
import BRPasswordInput from 'components/BRPasswordInput/BRPasswordInput';
import BRButton from 'components/BRButton/BRButton';
import BRInternationalPhone from 'components/BRInternationalPhone/BRInternationalPhone';
import BRFormInputs from 'components/BRFormInputs/BRFormInputs';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import VerifyPhoneModal from 'components/SignUp/components/VerifyPhoneModal.js/VerifyPhoneModal';

const SignupContainer = ({
  intl,
  usedPhoneNumber,
  setUsedPhoneNumber,
  country,
  setCountry,
  history
}) => {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
  const [businessNameValue, setBusinessNameValue] = useState(
    history?.location?.state?.payload?.businessName ||
      new URLSearchParams(history?.location?.search).get('business_name') ||
      ''
  );

  const formRef = useRef();
  const recaptchaRef = useRef();
  const { openModal } = useModal();

  const signInPath = getSignInPath();

  const getName = (fullName, position) => {
    switch (position) {
      case 'firstName':
        return fullName.trim().split(' ')[0];
      case 'lastName':
        return fullName.trim().substring(fullName.trim().indexOf(' ') + 1);
      default:
        break;
    }
  };

  const onFinish = async (values) => {
    if (!isRecaptchaVerified) {
      notify({
        msg: intl.formatMessage({ id: 'sign_up.verfiy_human' }),
        status: -1
      });
      return;
    }
    setIsConfirmLoading(true);

    const { email, password, fullName, phone, hearAboutUs, businessName } =
      values;
    const query = querySearch(history?.location?.search);
    const payload = {
      email,
      password,
      recaptchaValue: isRecaptchaVerified,
      profile: {
        firstName: getName(fullName, 'firstName'),
        lastName: getName(fullName, 'lastName'),
        phone: `${country?.codeNumber}${phone}`
      },
      countryId: country?.id,
      source: query?.utm_source,
      campaign: query?.utm_campaign,
      meduim: query?.utm_medium,
      ...(query && {
        userUrlData: {
          ...query
        }
      }),
      ...(isSllrUser()
        ? {
            businessName
          }
        : {
            heardAboutUsFrom: hearAboutUs
          })
    };

    const signUp = isSllrUser() ? sllrRegister : register;
    const regSrc =
      localStorage.getItem('regSrc') ||
      (isSllrUser() ? SLLR_REG_SRC : DASHBOARD_REG_SRC);

    try {
      const response = await signUp(regSrc, payload);
      localStorage.setItem('userInfo', JSON.stringify(response));
      localStorage.setItem('newToken', response.token);
      sendSegment('E_SIGNUP_START_WITH_BOSTA', {
        'First Name': getName(fullName, 'firstName'),
        'Last Name': getName(fullName, 'lastName'),
        email: email,
        'how did hear about us': hearAboutUs,
        phone: phone,
        'Signup Language': intl.locale
      });

      window?.analytics?.identify(email, {
        'User Email': email,
        Permissions: response?.user?.group?.name,
        'Dashboard Language': intl.locale,
        Phone: phone
      });

      openModal(VerifyPhoneModal, {
        phone: payload?.profile?.phone,
        email,
        userID: response?.user?._id,
        history: history,
        initialCountry: country,
        onOpen: () => {
          sendSegment(
            'Step1b_Showing-OTP',
            getAccountTypePropForSuccessMetrics()
          );
        }
      });
    } catch (error) {
      recaptchaRef.current?.reset();
      setIsRecaptchaVerified(false);

      if (error?.code === ERROR_CODES.EMAIL_EXISTS) {
        const payload = {
          email,
          profile: {
            fullName: fullName,
            phone: phone
          },
          country: country,
          ...(isSllrUser()
            ? {
                businessName
              }
            : {
                heardAboutUsFrom: hearAboutUs
              })
        };
        history.push({
          pathname: signInPath,
          state: {
            email,
            firstName: error?.data?.firstName,
            lastName: error?.data?.lastName,
            payload: payload,
            pathName: '/'
          }
        });
      } else if (ERROR_CODES.PHONE_EXISTS.includes(error?.errorCode)) {
        setUsedPhoneNumber(true);
      } else if (error?.errorCode === ERROR_CODES.BUSINESS_NAME_EXIST) {
        notify({
          msg: intl.formatMessage({
            id: 'sign_up.business_name_already_exist'
          }),
          status: -1
        });
        setIsConfirmLoading(false);
      } else {
        notify({ msg: error.message, error });
      }
    }
    setIsConfirmLoading(false);
  };

  const renderedSignInLink = (
    <div>
      <span>
        {intl.formatMessage({
          id: 'sign_up.new_sub_title'
        })}
      </span>
      <BRButton
        onClick={() => {
          history.push(signInPath);
        }}
        type="link-color"
        label={intl.formatMessage({
          id: 'sign_up.actions.signin_btn'
        })}
      />
    </div>
  );

  useEffect(() => {
    if (history?.location?.state?.payload) {
      const { payload } = history?.location?.state;
      setCountry(payload.country);
      formRef.current.setFieldsValue({
        email: payload?.email,
        fullName: payload?.profile?.fullName,
        phone: payload?.profile?.phone,
        ...(isSllrUser()
          ? {
              businessName: payload?.businessName
            }
          : {
              hearAboutUs: payload?.heardAboutUsFrom,
              terms: true
            })
      });
    }
  }, []);

  const handleCountryChange = (countryId) => {
    setCountry(COUNTRIES.find((country) => country.id === countryId));
  };

  return (
    <div className="br-signup">
      <Form ref={formRef} onFinish={onFinish}>
        <BRContentHeader
          title={
            <span className="display-md">
              {intl.formatMessage(
                {
                  id: `sign_up.${isSllrUser() ? 'sllr_title' : 'title'}`
                },
                {
                  span: (children) => businessNameValue && children
                }
              )}
              {isSllrUser() && businessNameValue && (
                <span className="br-signup__header__sllr-subtitle">
                  {businessNameValue}!
                </span>
              )}
            </span>
          }
          subtitle={!isSllrUser() && renderedSignInLink}
          titleClassName="br-signup__content-title display-md"
          isInternalComponent
        />
        {isSllrUser() && (
          <BRFormInputs
            className="br-signup-form__business-name"
            type={TEXT}
            inputTitle={intl.formatMessage({
              id: `contact_form_labels.${
                country?.codeName !== COUNTRIES[0].code
                  ? 'entity_name'
                  : 'business_name'
              }`
            })}
            formRef={formRef}
            name="businessName"
            rules={[
              requiredRule(
                intl.formatMessage({ id: 'sign_up.business_name_message' })
              )
            ]}
            setInputValue={setBusinessNameValue}
            initialValue={businessNameValue}
            hasFeedback
          />
        )}
        <BRFormInputs
          className="br-signup-form__fullname"
          type={TEXT}
          inputTitle={intl.formatMessage({
            id: 'contact_form_labels.full_name'
          })}
          formRef={formRef}
          name="fullName"
          rules={[
            fullNameRule(
              intl.formatMessage({
                id: 'sign_up.full_name_message'
              })
            ),
            requiredRule(
              intl.formatMessage(
                { id: 'form.required_custom' },
                {
                  label: intl.formatMessage({
                    id: 'contact_form_labels.full_name'
                  })
                }
              )
            )
          ]}
          hasFeedback
        />
        <Form.Item
          label={intl.formatMessage({
            id: 'sign_up.form_labels.country'
          })}
        >
          <Select
            options={isSllrUser() ? [COUNTRIES[0]] : COUNTRIES}
            fieldNames={{ label: 'name', value: 'id' }}
            onChange={handleCountryChange}
            value={country.id}
          />
        </Form.Item>
        <BRInternationalPhone
          country={country}
          setCountry={setCountry}
          formRef={formRef}
          usedPhoneNumber={usedPhoneNumber}
          setUsedPhoneNumber={setUsedPhoneNumber}
          disabledSelector
        />
        <Form.Item
          name="email"
          label={intl.formatMessage({
            id: 'contact_form_labels.email'
          })}
          rules={[
            requiredRule(
              intl.formatMessage(
                {
                  id: 'form.required_custom'
                },
                {
                  label: intl.formatMessage({
                    id: 'contact_form_labels.email'
                  })
                }
              )
            ),
            emailRule(
              intl.formatMessage({
                id: 'form.email_not_valid'
              })
            )
          ]}
          hasFeedback
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'sign_up.form_placeholders.email'
            })}
            type="email"
          />
        </Form.Item>
        <BRPasswordInput
          label={intl.formatMessage({
            id: 'sign_up.form_labels.password'
          })}
          formRef={formRef}
        />
        {!isSllrUser() && (
          <Form.Item
            className="br-signup__form__hear-about-us"
            name="hearAboutUs"
            label={intl.formatMessage({
              id: 'sign_up.form_labels.about_us'
            })}
            rules={[
              requiredRule(
                intl.formatMessage({
                  id: 'sign_up.how_required'
                })
              )
            ]}
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              placeholder={intl.formatMessage({
                id: 'sign_up.form_placeholders.about_us'
              })}
              className="ant-select__with-selected-indicator"
              virtual={false}
            >
              {Object.entries(MARKETING_CHANNELS).map(([key, value]) => {
                return (
                  <Select.Option key={value}>
                    <span className="ant-select__selected-indicator"></span>
                    <span className="ant-select__selected-label">
                      {intl.formatMessage({
                        id: `sign_up.company_options.${key.toLowerCase()}`
                      })}
                    </span>
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        {!isSllrUser() && (
          <TermsAndConditions formRef={formRef} country={country} />
        )}
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6Lcx14ckAAAAAO1O90bjhbH3Ji2LcBy1JZDCRSzW"
          onChange={(value) => setIsRecaptchaVerified(value)}
          onExpired={() => setIsRecaptchaVerified(false)}
          className="ReCAPTCHA"
        />
        <BRButton
          type="primary"
          htmlType="submit"
          className="full-width  br-registration-form-card__submit-btn button-lg"
          loading={isConfirmLoading}
          label={intl.formatMessage({
            id: 'sign_up.actions.create_account'
          })}
        />
      </Form>
      {isSllrUser() && (
        <div className="br-signup__signin-link">{renderedSignInLink}</div>
      )}
    </div>
  );
};
export default injectIntl(withRouter(SignupContainer));
