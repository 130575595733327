import { LOCALE } from "./intl-wrapper";

export const KEYBOARD = {
  ESCAPE: 'Escape'
};

export const KEYBOARD_KEY_CODE = {
  ESCAPE: 27,
  ENTER: 13
};

export const EVENTS = {
  KEYDOWN: 'keydown',
  CLICK: 'click'
};

export const UPLOADED_FILE_TYPES = {
  JPG: 'image/jpeg',
  PNG: 'image/png',
  GIF: 'image/gif',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV: 'text/csv'
};

export const FEATURES_CONFIGURATIONS_KEYS = {
  HIDE_ENABLE_sLLR_PAY_BANNER: 'hideEnableSllrPayBanner',
  SELECT_FROM_PRODUCTS: 'selectFromProducts'
};

export const AVAILABILITY = {
  DROPOFF: 'dropOffAvailability',
  PICKUP: 'pickupAvailability'
};

export const CONTEXT = {
  dropOffAvailability: 'dropoff',
  pickupAvailability: 'pickup'
};

export const EGY_BOSTA_MATERIALS_ID = 'HnRWYTmEgoBELeiPd';
export const KSA_BOSTA_MATERIALS_ID = '2LbbTCDYIZC0xq8iPkh0M';
export const BOSTA_MATERIAL_IDS = [
  EGY_BOSTA_MATERIALS_ID,
  KSA_BOSTA_MATERIALS_ID
];

export const OPEN_PACKAGE_FEES_AVAILABILTY = {
  EG: true,
  SA: false
};

export const PACK_PRICE_IMAGE = {
  [LOCALE.EN]:
    'https://storage.googleapis.com/bosta-fe/website/english-pack-price.png',
  [LOCALE.AR]:
    'https://storage.googleapis.com/bosta-fe/website/arabic-pack-price.png'
};

export const PACK_PRICE_DATE_TO_BE_REMOVED = '2024-05-15';
