import { fmt } from 'components/IntlWrapper/IntlWrapper';
import { formatNumbersBasedOnLanguage } from 'utils/intl-wrapper';

export const CURRENCY = (cod) => ({
  EG: {
    type: 'EGP',
    localized: fmt(
      { id: 'common.egp_amount' },
      {
        cod: cod
      }
    ),
    vat: 0.14
  },
  SA: {
    type: 'SAR',
    localized: fmt(
      { id: 'common.saudi_amount' },
      {
        cod: cod
      }
    ),
    vat: 0.15
  },
  AE: {
    type: 'AED',
    localized: fmt(
      { id: 'common.aed_amount' },
      {
        cod: cod
      }
    ),
    vat: 0.15
  },
  QA: {
    type: 'QAR',
    localized: fmt(
      { id: 'common.qar_amount' },
      {
        cod: cod
      }
    ),
    vat: 0.15
  },
  OM: {
    type: 'OMR',
    localized: fmt(
      { id: 'common.omr_amount' },
      {
        cod: cod
      }
    ),
    vat: 0.15
  },
  KW: {
    type: 'KWD',
    localized: fmt(
      { id: 'common.kwd_amount' },
      {
        cod: cod
      }
    ),
    vat: 0.15
  }
});

export const FAWRY_AVAILABILITIY = {
  EG: true,
  SA: false
};

export const PAYMOB_AVAILABILITY = {
  EG: true,
  SA: false
};

export const MOYASAR_AVAILABILITY = {
  EG: false,
  SA: true
};

export const BOSTA_CAPITAL_AVAILABILITY = {
  EG: true,
  SA: false
};

export const SLLR_PAY_AVAILABLITITY = {
  EG: true,
  SA: true
};

export const CIRCLE_K_AVAILABLITITY = {
  EG: true,
  SA: false
};

export const CAN_BUY_EXTRA_FLYERS = {
  EG: true,
  SA: false
};

export const BANK_RECHARGE_AVAILABILITY = {
  EG: true,
  SA: false
};

export const SHOW_INTERNATIONAL_BANKS = {
  EG: true,
  SA: true,
  AE: true
};

export const ADD_SECOND_BANK = {
  EG: true,
  AE: false,
  SA: false
};
