import { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import querySearch from 'stringquery';

import { LOCALE } from 'constants/intl-wrapper';
import { handleLanguageChange, isSllrUser } from 'utils/helpers';
import { mediaHook } from 'customHooks';
import { sendSegment } from 'utils/segment';
import { isDomesticShippingAvailable } from 'constants/countries/countries-mapping';

import BRButton from 'components/BRButton/BRButton';

import BostaLogo from 'assets/imgRevamp/bosta_logo.svg';
import BostaLogoArabic from 'assets/imgRevamp/bosta_arabic.svg';
import SllrLogoEN from 'assets/bosta-icons/SllrLogoEN.svg';
import { ReactComponent as Language } from 'assets/bosta-icons/Language.svg';
import { ReactComponent as Hexagon } from 'assets/imgRevamp/hexagon.svg';
import { ReactComponent as RedHexagon } from 'assets/bosta-icons/RedHexagonSmall.svg';
import { ReactComponent as ExternalLink } from 'assets/bosta-icons/external-link.svg';
import { ReactComponent as LeftHexagon } from 'assets/imgRevamp/RegisterationLeftHexagon.svg';

import './RegistrationContainer.less';

const RegistrationContainer = ({
  intl,
  mobileScreenSizes: { isSmallMobileScreen },
  content,
  actionLink,
  className,
  hideTrackOrderInHeader,
  isLoading,
  tip = '',
  stepper,
  footer,
  signUpPage,
  fromSllr = false
}) => {
  useEffect(() => {
    window?.fcWidget?.hide();
  }, []);

  const isFromSllr = isSllrUser() || fromSllr;

  const onChangeLanguage = () => {
    const { lang, ...restParams } = querySearch(window.location.search);
    let modifiedPath;
    if (lang) {
      const modifiedQuerySearch = new URLSearchParams(restParams).toString();
      modifiedPath = `${window.location.pathname.split('/')[1]}${
        modifiedQuerySearch && `?${modifiedQuerySearch}`
      }`;
    }

    handleLanguageChange(
      intl.locale === LOCALE.EN ? LOCALE.AR : LOCALE.EN,
      modifiedPath
    );
  };

  const handleGoToWebsitePage = () => {
    if (signUpPage) {
      sendSegment('Step1a_Create-Account-Track-link');
    } else {
      sendSegment('Step1a_Login-Track-link');
    }
    window.open(
      `https://bosta.co/${
        intl.locale === LOCALE.AR ? 'ar' : 'en'
      }/tracking-shipments`,
      '_blank'
    );
  };

  return (
    <div
      className={classnames(
        'br-registration-container',
        {
          [className]: !!className
        },
        { 'br-registration-container__with-footer': !!footer }
      )}
    >
      <div className="br-registration-container__header">
        <img
          alt="Bosta logo"
          src={
            isFromSllr
              ? SllrLogoEN
              : intl.locale === LOCALE.EN
              ? BostaLogo
              : BostaLogoArabic
          }
          className="br-registration-container__logo"
        />
        {!isSmallMobileScreen && isDomesticShippingAvailable() && stepper}
        <span className="br-registration-container__header__action">
          {!isSmallMobileScreen && !hideTrackOrderInHeader && !isFromSllr && (
            <BRButton
              type="link-color"
              label={intl.formatMessage({ id: 'login.track_my_order' })}
              className="button-md  br-registration-container__track-order"
              suffixIcon={<ExternalLink />}
              onClick={handleGoToWebsitePage}
            />
          )}
          <BRButton
            type="link-gray"
            label={intl.locale === LOCALE.EN ? 'عربي' : 'English'}
            className="button-md br-registration-container__language-btn"
            suffixIcon={<Language />}
            onClick={onChangeLanguage}
          />
        </span>
      </div>
      {!footer && (
        <div className="br-registration-container__hexagon">
          <Hexagon />
        </div>
      )}
      <span className="br-registration-container__red-hexagon">
        <RedHexagon />
      </span>
      <span className="br-registration-container__left-hexagon">
        <LeftHexagon />
      </span>
      <div className="br-registeration-container__bottom-line" />
      <div className="br-registration-content">
        {isSmallMobileScreen && stepper}
        <div className="br-registration-form-card">
          {isLoading ? (
            <div className="br-registration-container__loading">
              <Spin indicator={<LoadingOutlined />} tip={tip} />
            </div>
          ) : (
            content
          )}
          {isSmallMobileScreen && actionLink && !isLoading && (
            <div className="br-registration-action-link">{actionLink}</div>
          )}
          {!isLoading && footer}
        </div>
        {!isSmallMobileScreen && actionLink && !isLoading && (
          <div className="br-registration-action-link">{actionLink}</div>
        )}
      </div>
    </div>
  );
};

export default withRouter(injectIntl(mediaHook(RegistrationContainer)));
