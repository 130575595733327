import { bostaHttp, sllrHttp } from 'http.js';
import { downloadHeader } from 'utils/download';
import { BOSTA_MATERIAL_IDS } from 'constants/common';
import { userInfo } from 'utils/helpers';

const isBostaMaterialBusinessId = () =>
  BOSTA_MATERIAL_IDS.includes(userInfo?.businessAdminInfo?.businessId);

const baseHTTP = isBostaMaterialBusinessId() ? bostaHttp : sllrHttp;
const baseURL = isBostaMaterialBusinessId() ? '/draft-orders' : '/salesOrders';

export const getDraftOrders = (payload) => {
  return baseHTTP.get(baseURL, payload);
};

export const getDraftOrderById = (id) => {
  return baseHTTP.get(
    `${baseURL}/${id}${isBostaMaterialBusinessId() ? '' : '/details'}`
  );
};

export const editDraftOrder = (id, payload) => {
  return baseHTTP.put(`${baseURL}/${id}`, payload);
};

export const confirmDraftOrder = (id, payload) => {
  return baseHTTP.post(`${baseURL}/${id}/confirm`, payload);
};

export const deleteDraftOrder = (id, payload) => {
  return baseHTTP.delete(`${baseURL}/${id}`, payload);
};

export const massConfirmDraftOrders = (payload) => {
  return baseHTTP.post(`${baseURL}/bulkConfirm`, payload);
};

export const massCancelDraftOrders = (payload) => {
  return baseHTTP.post(`${baseURL}/bulkCancel`, payload);
};

export const exportDraftOrders = (payload) => {
  return bostaHttp.get('/draft-orders/export/orders', payload, downloadHeader);
};

export const getDraftOrderProducts = (id) => {
  return baseHTTP.get(`${baseURL}/${id}/items`);
};

export const deleteFlyersDraftOrder = (id, payload) => {
  return bostaHttp.delete(
    `/draft-orders/${id}?creationSrc=FLYERS_DASHBOARD`,
    payload
  );
};
