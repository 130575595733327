import React, { useEffect } from 'react';
// sidebar nav config
import { injectIntl } from 'react-intl';

import useHotjar from 'react-use-hotjar';
import dayjs from 'dayjs';

import { SLLR_URL } from 'constants/products';
import { DATE_FORMAT } from 'constants/pickups';

function HotjarHoc(props) {
  const { intl, children } = props;

  const { identifyHotjar } = useHotjar();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const storeInfo = userInfo?.user?.businessAdminInfo?.storeInfo;

  useEffect(() => {
    if (window.env?.HOTJAR_API_KEY)
      identifyHotjar(userInfo?.user?.emails[0]?.address, {
        // Add your own custom attributes here. Some EXAMPLES:
        'User Email': userInfo
          ? userInfo?.user?.emails[0]?.address
          : 'Anonymous',
        'Dashboard Language': intl.locale,
        Permissions: userInfo?.user?.group?.name,
        'Business Name': userInfo
          ? userInfo?.user?.businessAdminInfo?.businessName
          : 'Anonymous',
        Phone: userInfo ? userInfo?.user?.profile?.phone : 'Anonymous',
        'Business Type': userInfo?.user?.monthlyShipmentVolume,
        'Business Tier': userInfo?.user?.businessAdminInfo?.businessTier,
        Sllr: !storeInfo?.storeLink
          ? 'N/A'
          : storeInfo?.isPublished
          ? 'Published'
          : 'Unpublished',
        'Sllr Creation Date':
          storeInfo?.createdAt &&
          dayjs(storeInfo?.createdAt).format(DATE_FORMAT),
        'Store Link':
          storeInfo?.storeLink && `${SLLR_URL}${storeInfo.storeLink}`,
        'Last confirmed order date':
          storeInfo?.lastOrderConfirmedOrderAt &&
          dayjs(storeInfo?.lastOrderConfirmedOrderAt).format(DATE_FORMAT)
      });
    if (window.env.SEGMENT_API_KEY && userInfo) {
      window.analytics.identify(userInfo.user.emails[0].address, {
        'User Email': userInfo.user.emails[0].address,
        'Business Name':
          userInfo.user.businessAdminInfo?.businessName !== undefined
            ? userInfo.user.businessAdminInfo.businessName
            : null,
        Phone: userInfo.user.profile.phone || '',
        Permissions: userInfo?.user?.group?.name,
        'Business Type': userInfo.user.monthlyShipmentVolume || '',
        'Dashboard Language': intl.locale,
        'Business Tier': userInfo?.user?.businessAdminInfo?.businessTier
      });
    }
  }, [identifyHotjar, userInfo, intl]);

  return <>{children}</>;
}

export default injectIntl(HotjarHoc);
